<template>
  <div class="login-container">
    <div class="title">
      <h1>
        <span v-for="(item ,index) in title" :key="index"  class="curve-text">{{item}}</span>
      </h1>
    </div>
    <div class="login-form">
      <div class="login">
        <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            label-position="labelPosition"
        >
          <el-form-item prop="username">
            <el-input
                v-model="loginForm.username"
                @keyup.enter="handleLogin"
                placeholder="请输入员工账号"
            >
              <template #prepend>
                <i class="el-icon-user"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
                v-model="loginForm.password"
                type="password"
                placeholder="请输入员工密码"
                show-password
                @keyup.enter="handleLogin"
            >
              <template #prepend>
                <i class="el-icon-lock"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item prop="captcha">
                  <el-input v-model="loginForm.captcha" @keyup.enter="handleLogin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="captcha-container">
                <el-image v-if="requestCodeSuccess" :src="randCodeImage" @click="handleChangeCheckCode"></el-image>
                <img v-else src="../../assets/checkcode.png" alt="点击刷新验证码" @click="handleChangeCheckCode">
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="请选择你的店铺" :visible.sync="dialogVisible" width="30%" class="shop-dialog" :showClose="false">
      <el-select v-model="selectedShop" placeholder="请选择你的店铺" @change="setShopName">
        <el-option v-for="item in shopInfoList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCheck">取消</el-button>
        <el-button type="primary" @click="confirmShop">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import index, { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      title:'昆明树木园收银系统',
      shopInfoList: [],
      shopInfo: '0',
      selectedShop: null,
      dialogVisible: false,
      currdatetime: '',
      requestCodeSuccess: false,
      randCodeImage: '',
      loginForm: {
        username: '',
        password: '',
        captcha: '',
        checkKey: ''
      },
      labelPosition: 'right',
      rules: {
        username: [
          { required: true, message: '请输入员工账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入员工密码', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {

    ...mapState({ token: state => state.token,
      userInfo: state => state.userInfo}),
  },
  created() {
    this.handleChangeCheckCode();
  },

  methods: {
    ...mapActions(["setUserInfo", "setToken", "setShopInfo", "setShopInfoList","setRoleList"]),
    cancelCheck() {
      this.$message.warning('请先选择所属店铺');
    },
    getHeadShop() {
      console.log("========getHeadShop")
      let that = this
      this.$http.get("/base/basWarehouse/getHeadShop").then((response) => {
        if (response.success) {
          this.shopInfoList = response.result;
          this.setShopInfoList(this.shopInfoList);
          this.$http.get(`/shopUser/setRecentShop?type=get`).then(res => {
            console.log("shopUser/setRecentShop?type=get",res)
            console.log("=======",this.userInfo)
            if (res.success) {
              this.shopInfo = res.result;
              this.setShopInfo(this.shopInfo);

              that.$http.get("/user/basWarehouseRoleUser/queryByUserId?id="+this.userInfo.id).then(r=>{
                console.log("/user/basWarehouseRoleUser/queryByUserId?id=",r)
                if(r.code==200){
                  this.setRoleList(r.result)
                }
              })
              this.$message.success("登录成功");
              this.$router.push({ path: '/index' });
            } else {
              that.$http.get("/user/basWarehouseRoleUser/queryByUserId?id="+this.userInfo.id).then(r=>{
                if(r.code==200){
                  this.setRoleList(r.result)
                }
              })
              if (this.shopInfoList.length > 1) {
                this.dialogVisible = true;
              } else if (this.shopInfoList.length == 1) {
                this.shopInfo = this.shopInfoList[0];
                this.setShopInfo(this.shopInfo);
                this.$http.get(`/shopUser/setRecentShop?type=setShop&shopId=${this.shopInfo.id}`);
                this.$router.push({ path: '/index' });
              } else {
                this.$message.error(response.message);
                this.clearUserInfo();
                this.$router.replace({ path: "/login" });
              }
            }
          });
        }
      });
    },
    confirmShop() {
      if (this.selectedShop == null) {
        this.$message.error("请选择店铺");
        return;
      }
      this.dialogVisible = false;
      this.$message.success(`已选择店铺: ${this.shopInfo.name}`);
      this.$router.push({ path: '/index' });
    },
    checkShop() {
      this.dialogVisible = true;
    },
    setShopName(id) {
      this.shopInfoList.forEach(item => {
        if (item.id == id) {
          this.shopInfo = item;
        }
      });
      this.setShopInfo(this.shopInfo);
      this.$http.get(`/shopUser/setRecentShop?shopId=${id}&type=setShop`);
    },
    handleChangeCheckCode() {
      this.currdatetime = new Date().getTime();
      this.$http.get(`/sys/randomImage/${this.currdatetime}`).then(res => {
        if (res.success) {
          this.randCodeImage = res.result;
          this.requestCodeSuccess = true;
        } else {
          this.$message.error(res.message);
          this.requestCodeSuccess = false;
        }
      }).catch(() => {
        this.requestCodeSuccess = false;
        this.$message.error(`网络请求异常`);
      });
      this.loginForm.captcha = '';
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loginForm.checkKey = this.currdatetime;
          this.$http.post('/sys/login', this.loginForm).then(res => {
            if (res.success) {
              this.setToken(res.result.token);
              this.setUserInfo(res.result.userInfo);
              this.getHeadShop();
            } else {
              this.$message.error(res.message);
              this.handleChangeCheckCode();
            }
          });
        } else {
          this.$message.error('请完整填写登录信息');
        }
      });
    }
  }
};
</script>


<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #1d4350 10%, #a43931 80%);
  color: #fff;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  position: relative; /* Add this to help with positioning of curve effect */
}

.title h1 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  /**background-image: linear-gradient(to right, #f80505, #f1df0d, #033c04);**/
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 1px 1px 2px rgb(46, 152, 42), 2px 2px 4px rgba(14, 14, 14, 0.3);
  position: relative;
  display: inline-block;
}

@keyframes text-shimmer {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.login-form {
  width: 100%;
  max-width: 450px;
  padding: 40px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 50% / 20%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  overflow: hidden; /* To prevent overflow of child elements */
}

.login {
  width: 100%;
}

.el-form-item {
  margin-bottom: 25px;
}

.el-button {
  width: 100%;
  padding: 12px 0;
  font-size: 1.2rem;
}

.el-input__inner {
  text-align: center;
  padding: 12px;
  font-size: 1.1rem;
}

.captcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-dialog ::v-deep .el-input__inner {
  border-radius: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.dialog-footer .el-button {
  font-size: 1rem;
}

.login-button {
  position: relative;
  background: linear-gradient(135deg, #6e7f80 0%, #cfdee7 100%);
  color: #fff;
  font-size: 1.3rem;
  padding: 15px 0;
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.login-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 10%, transparent 10%) 0 0;
  background-size: 50% 50%;
  transition: background-position 0.3s ease;
}

.login-button:hover::before {
  background-position: 100% 100%;
}

.login-button:active {
  transform: scale(0.95);
}
</style>
