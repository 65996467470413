import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shopInfoList: [],
    shopInfo: {},
    userInfo: {},
    token: '',
    roleList:[],
    registrationList:[]
  },
  getters: {
    shopInfoList: state => state.shopInfoList,
    shopInfo: state => state.shopInfo,
    userInfo: state => state.userInfo,
    token: state => state.token,
    roleList:state => state.roleList,
    registrationList:state => state.registrationList
  },
  mutations: {
    updateShopInfo(state, shopInfo) {
      state.shopInfo = shopInfo;
    },
    setShopInfoList(state, shopInfoList) {
      state.shopInfoList = shopInfoList;
    },
    setShopInfo(state, shopInfo) {
      state.shopInfo = shopInfo;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    setUserRoleList(state, roleList) {
      state.roleList = roleList;
      window.localStorage.setItem('roleList', JSON.stringify(roleList));
    },
    setToken(state, token) {
      state.token = token;
      window.localStorage.setItem('token', token);
    },
    setRegistrationList(state ,list){
      console.log(list)

      state.registrationList = list
    },
    addRegistrationList(state ,list){
      console.log(list)
      state.registrationList.push(list)
    },
    clearUserInfo(state) {
      state.shopInfoList = [];
      state.shopInfo = {};
      state.userInfo = {};
      state.token = '';
      state.roleList =[];
      state.registrationList=[];
      window.localStorage.removeItem('userInfo');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('roleList');
    }
  },
  actions: {
    setRegistrationList({ commit }, list){
      commit('setRegistrationList', list);
    },
    setShopInfoList({ commit }, shopInfoList) {
      commit('setShopInfoList', shopInfoList);
    },
    setShopInfo({ commit }, shopInfo) {
      commit('setShopInfo', shopInfo);
    },
    setUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo);
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    setRoleList({commit},roleList ){
      console.log("setRoleList",roleList)
      commit('setUserRoleList',roleList)
    }
  },
  modules: {},
  plugins: [
    createPersistedstate({
      reducer(val) {
        return {
          shopInfoList: val.shopInfoList,
          shopInfo: val.shopInfo,
          userInfo: val.userInfo,
          token: val.token,
          roleList: val.roleList,
          registrationList: val.registrationList
        };
      }
    })
  ]
});
