import axios from 'axios';
import store from '@/store' 
import router from '@/router'
//引入elment-ui的提示框
import { Message } from 'element-ui';
// 创建axios实例
const instance = axios.create({
  //baseURL: 'http://192.168.50.170:8080/liwen-erp', //根据自己公网ip修改
  //baseURL: 'http://ll:8080/liwen-erp', //根据自己公网ip修改//本地测试
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 90000,
});
const err = (error) => {
  if (error.response) {
    
    switch (error.response.status) {
      case 401:
        // store.dispatch('LogOut').then(() => {
        //   location.reload()// 为了重新实例化vue-router对象 避免bug
        // })
        router.push({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
        location.reload();
    }
  }
  console.log(error);
}
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')? localStorage.getItem('token') : store.getters.token; // 获取token
    if (token) {
      config.headers[ 'X-Access-Token' ] = token ;
    }
    return config;
  },
  function (error) {
    Message.error('网络请求错误:'+error);
    return Promise.reject(error);
  }
);
// response interceptor
instance.interceptors.response.use((response) => {

  if (response.data.code === 401) {
    Message.error('登录失效，请重新登录');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.$router.push('/login');
  }
  return response.data
}, 
function (error) {
  Message.error('网络请求错误:'+error);
  err(error);
  return Promise.reject(error);
}
)
export default instance;