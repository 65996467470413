<template>
    <div class="payment-container">
      <el-form :model="paymentForm" @submit.native.prevent="submitPayment">
        <el-form-item label="收款码">
          <el-input v-model="paymentForm.qrCode" placeholder="请用扫码枪扫描收款码" />
        </el-form-item>
        
        <el-form-item label="支付方式">
          <div class="payment-methods">
            <el-radio-group v-model="paymentForm.paymentMethod">
              <el-radio label="wechat">
                <img src="https://pic.rmb.bdstatic.com/bjh/down/a36cf5452e2d81e173add6b343ef2e5b.png" alt="微信支付" class="payment-icon"/>
              </el-radio>
              <el-radio label="alipay">
                <img src="https://q3.itc.cn/q_70/images03/20240119/e6d0d3a05553429097179be1a6ad1aa5.jpeg" alt="支付宝支付" class="payment-icon"/>
              </el-radio>
              <el-radio label="bankcard">
                <img src="https://taijiyunwaitui.com/wp-content/uploads/2022/04/25eb9e4bdf2944248251071846c0ae99.jpg" alt="银行卡支付" class="payment-icon"/>
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" @click="submitPayment">支付</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        paymentForm: {
          qrCode: '',
          paymentMethod: ''
        }
      };
    },
    methods: {
      submitPayment() {
        if (this.paymentForm.qrCode && this.paymentForm.paymentMethod) {
          // 在这里处理支付逻辑，例如调用支付API
          console.log('支付信息', this.paymentForm);
          this.$message.success('支付成功');
        } else {
          this.$message.error('请填写所有信息');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .payment-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
  
  .payment-methods {
    display: flex;
    justify-content: space-between;
  }
  
  .payment-icon {
    width: 50px;
    height: 50px;
  }
  
  .el-form-item {
    margin-bottom: 20px;
  }
  </style>
  