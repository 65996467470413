<template>
  <div class="main_box" :style="{ height: screenHeight + 'px' }">

    <div class="shouyin-header2">
      <h1>昆明树木园收银系统</h1>
      <div style="display: flex;">
        <el-badge  class="item">
          <el-button size="small"  @click="goodsReceiving">入库</el-button>
        </el-badge>
        <el-badge :value="registrationList.length" class="item">
          <el-button size="small" @click ="registrationDialog =true">取单</el-button>
        </el-badge>
      </div>
      <Logout @logout="logout" @clearGoods="clearGoods"></Logout>
    </div>
    <div style="display: flex;justify-content: space-between;height: 100%">
      <div class="left">
        <div class="shouyin-main" id="app">
          <!-- 左侧扫码部分 -->
          <div class="scan-area">
            <div class="scan-form">
              <input
                  @blur="blur"
                  type="text"
                  class="input-box"
                  readonly="true"
                  autofocus
                  v-model="goodsCode"
                  :placeholder="inputMsg"
                  @keydown="showGoods"
                  ref="inputRef"
              />
              <input
                  type="button"
                  class="btn-box"
                  @click="manualInput"
                  value="手动输入"
              />
            </div>
            <div class="scan-list">
              <div class="scan-list-content contentinfo-box">
                  <table cellspacing="0">
                  <thead>
                  <tr>
                    <!--                  <th>图片</th>-->
                    <th>品名</th>
                    <th>数量</th>
                    <th>单价</th>
                    <th>小计</th>
                  </tr>
                  </thead>
                  <tbody class="list-wrapper">
                    <tr v-for="(good, index) in goods" :key="index">
                      <!--                  <td>-->
                      <!--                    <el-image-->
                      <!--                        style="width: 100px; height: 100px"-->
                      <!--                        :src="good.thumb"-->
                      <!--                        fit="fit"-->
                      <!--                    ></el-image>-->
                      <!--                  </td>-->
                      <td style="max-width: 100px;">{{ good.name }}<div style="font-size: 12px">
                        {{ good.specificationName ?  good.specificationName  : '' }}</div></td>
                      <td>
                        <button @click="reduce(index)">-</button>
                        {{ good.num }}
                        <button @click="addition(index)">+</button>
                      </td>
                      <td>{{ good.salePrice }}</td>
                      <th>{{ (good.salePrice * good.num).toFixed(2) }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="scan-list-action">
                <div class="btn-box btnstyle">
                  <div class="txt-box" style="padding:10px 0">
                    <span style="margin-right: 10px">数量：<span class="goods-type-num">{{totalCount}}</span></span>
                    <span>总金额：<span class="goods-total-price price">{{ totalPrice}}</span></span>
                  </div>
                  <div style="display: flex">
                    <input v-if="goods.length>0" type="button" style="margin-left: 0;margin-rigth: 0" value="挂单"  @click="registrationAdd" />
                    <input type="button" style="margin-left: 0;margin-rigth: 0" value="清空商品" @click="clearGoods"/>
                    <input type="button" style="margin-left: 0;margin-rigth: 0" value="结算" @click="gotoPay"/>
                  </div>
                </div>

                <!--              <div class="other-list">-->
                <!--                <div class="other-list-time">-->
                <!--                  <div>-->
                <!--                  <span class="operator-name"-->
                <!--                  >操作员：{{ this.userInfo.realname }}</span-->
                <!--                  >-->
                <!--                    <span class="time">{{ formatTime }}</span>-->
                <!--                  </div>-->
                <!--                  <img-->
                <!--                      v-if="isOnline"-->
                <!--                      alt="网络已连接"-->
                <!--                      src="https://img.51miz.com/Element/00/88/14/85/09fcb840_E881485_e7565f91.png"-->
                <!--                      class="status-icon"-->
                <!--                  />-->
                <!--                  <img-->
                <!--                      v-else-->
                <!--                      alt="网络未连接"-->
                <!--                      src="https://pic.616pic.com/ys_bnew_img/00/24/28/a1X7m3RsBi.jpg"-->
                <!--                      class="status-icon"-->
                <!--                  />-->
                <!--                </div>-->
                <!--                <div><span class="operator-name">技术支持@云南励文科技发展有限公司</span></div>-->
                <!--              </div>-->
              </div>
            </div>
          </div>
          <el-dialog
              title="挂单列表"
              :visible.sync="registrationDialog"
              width="60%"
              :close-on-click-modal="false"
              @close="closeRegistrationDialog"
          >

            <el-table height="300" :data="registrationList">
              <el-table-column prop="id" label="挂单号" width="200">

              </el-table-column>
              <el-table-column
                  prop="date"
                  label="挂单时间"
                  width="260"
              ></el-table-column>
              <el-table-column prop="goods.length" label="商品种类"></el-table-column>
              <!-- <el-table-column prop="address" label="数量"></el-table-column> -->
              <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <el-button size="mini" @click="take(scope)"
                    >取单
                    </el-button
                    >
                    <el-button size="mini" @click="takedel(scope.row)"
                    >删除
                    </el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>



            <el-button @click="closeRegistrationDialog" type="warning" style="margin-top: 2%"
            >关闭
            </el-button
            >
          </el-dialog>
          <!-- 手动输入商品 -->
          <el-dialog
              title="手动添加商品"
              :visible.sync="manualDialog"
              width="60%"
              :close-on-click-modal="false"
              @close="closeDialog"
          >
            <ManualInput
                ref="closeChild"
                @searchEvent="queryGoodsById"
            ></ManualInput>
            <el-table height="300" :data="goodListTable">
              <el-table-column label="商品图片" width="200">
                <template slot-scope="scope">
                  <el-image

                      style="width: 100px; height: 100px"
                      :src="scope.thumb"
                      fit="true"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="品名"
                  width="150"
              ></el-table-column>
              <el-table-column prop="salePrice" label="单价"></el-table-column>
              <!-- <el-table-column prop="address" label="数量"></el-table-column> -->
              <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleAdd(scope.$index, scope.row)"
                  >添加
                  </el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="closeDialog" type="warning" style="margin-top: 2%"
            >关闭
            </el-button
            >
          </el-dialog>
          <!-- 支付模态框 -->
          <el-dialog
              style="overflow: hidden"
              top="8vh"
              :visible.sync="checkPay"
              width="55%"
              :showClose="false"
              @open="openDialoged"
              v-loading="loading"
              element-loading-text="正在支付中。。。。"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :close-on-click-modal="false"
          >
            <Pay
                :goodsData="sendGoodsTake"
                @cancel="noShowPay"
                @submit="submit"
                v-if="showPay"
            ></Pay>
            <!--        <div slot="footer" class="dialog-footer" v-if="showPay">-->
            <!--          <el-button @click="noShowPay">取 消</el-button>-->
            <!--        </div>-->
          </el-dialog>
          <!-- 支付成功模态框 -->
          <div v-if="payResult" class="payment-success">
            <div class="popup">
              <div class="checkmark">✓</div>
              <h2>支付成功</h2>
              <p>您的支付已成功完成，谢谢使用。</p>
              <el-button type="primary" @click="close"
              >关闭({{ countdown }}后自动关闭)
              </el-button
              >
            </div>
          </div>
          <!-- 支付失败模态框 -->
          <div v-if="payFailed" class="payment-failed">
            <div class="popup">
              <div class="checkmark1">✕</div>
              <h2>支付失败</h2>
              <p>支付失败,请稍后再试!</p>
              <el-button type="primary" @click="close"
              >关闭({{ countdown }}后自动关闭)
              </el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <CategorySidebar @selectCategory="fetchProductsByCategory"></CategorySidebar>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import Logout from "@/components/logout/index";
import ManualInput from "@/view/mian/manualInput";
import HandleCashPayment from "@/view/mian/handleCashPayment";
import HandleEPayMent from "@/view/mian/handleEPayMent";
import Pay from "@/view/mian/pay";
import "@/assets/css/base.css";
import "@/assets/css/scan.css";
import "@/assets/css/index.css";
import "@/assets/css/paid.css";
import CategorySidebar from "@/view/mian/categorySidebar.vue";


export default {
  components: {
    Logout,
    ManualInput,
    HandleCashPayment,
    HandleEPayMent,
    Pay,
    CategorySidebar
  },
  data() {

    return {
      addRegistration:true,
      registrationDialog:false,
     // registrationList:[], //挂单列表
      payState: true,
      netWorkStatus: null,
      now: new Date(),
      payFailed: false,
      loading: false,
      countdown: 3,
      countdownInterval: null,
      payResult: false,
      billNo: "",
      batchNo: "",
      warehouseAndInvoiceInfo: {},
      manualDialog: false,
      radio: "",
      showPay: false,
      checkPay: false,
      handleCashPayment: false,
      handleEPayment: false,
      inputMsg: "请使用扫码设备扫码",
      goods: [],
      goodListTable: [],
      showPayType: true,
      goodsCode: "",
      totalCount: 0,
      totalPrice: 0,
      screenWidth: 0,
      screenHeight: 0,
       options :{
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // 使用24小时制
      }
    };
  },
  watch: {
    isOnline(newStatus) {
      this.netWorkStatus = newStatus;
    },
  },
  computed: {
    isOnline() {
      return this.netWorkStatus != null ? this.netWorkStatus : navigator.onLine;
    },
    formatTime() {
      let hours = this.now.getHours();
      let minutes = this.now.getMinutes();
      let seconds = this.now.getSeconds();
      let year = this.now.getFullYear();
      let month = this.now.getMonth() + 1;
      let day = this.now.getDate();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    ...mapState({
      registrationList:(state)=>state.registrationList,
      shopInfo: (state) => state.shopInfo,
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    setTimeout(() => {
      this.autoFunction();
    }, 1000);
  },
  methods: {
    ...mapActions(["setRegistrationList"]),
    takedel(row){
      for (let i = 0; i < this.registrationList.length; i++) {
        if(this.registrationList[i].id==row.id){
          this.registrationList.splice(i,1)
          this.setRegistrationList(this.registrationList)
          this.$message.success("删除成功！")
          break
        }
      }
    },
    take(data){
console.log(data)

      for (let i = 0; i < this.registrationList.length; i++) {
        if(this.registrationList[i].id==data.row.id){
          this.registrationList.splice(i,1)
          this.setRegistrationList(this.registrationList)
          break
        }
      }
      console.log(this.registrationList)
      this.goods = data.row.goods
      this.$message.success("取单成功")
      this.closeRegistrationDialog()
    },
    closeRegistrationDialog(){
      console.log(999)
      this.registrationDialog =false
    },
    registrationAdd(){

      if(this.registrationList.length>9){
        this.$message.error("最多只能挂10单")
        return
      }
if(this.addRegistration){
  this.addRegistration=false
   let now =   new Date();
      let obj ={
        id:now.getTime(),
        date:now.toLocaleString('default', this.options),
        goods:this.goods
      }
      console.log(obj)
      this.registrationList.push(obj)
  this.addRegistration = true
  this.setRegistrationList(this.registrationList)
  this.clearGoods()
  this.$message.success("挂单成功")
}
    },
    staticUrl(url) {
      //console.log(url)
      return process.env.VUE_APP_BASE_API + url
    },
    fetchProductsByCategory(data) {
if(data.specificationName){
  console.log("data",data)
}

      let goodList = JSON.parse(JSON.stringify(this.goods))
        console.log("data",data.specificationName)
        console.log("==============================",this.goods.length)
        console.log(this.goods)

console.log(goodList)
        var tt = -1;
        for (let i = 0; i < goodList.length; i++) {

          if (goodList[i].code === data.code) {
            if(data.specificationName&&this.goods[i].specificationName===data.specificationName){
              console.log(i)
              this.goods[i].num++;
              this.goodsCode = "";
              tt = 1;
              break;
            }else if(!data.specificationName){
              this.goods[i].num++;
              this.goodsCode = "";
              tt = 1;
              break;
            }

          }
        }
        //tt作为一个信号量当tt不等于-1时表示商品已经存在，只需要添加数量
        if (tt === -1) {
          console.log("添加新的商品结算")
          data.num = 1;
          var goods = data;


          this.goods.unshift(goods);
          this.goodsCode = "";
          this.inputMsg = "请使用扫码设备扫码";
        }


        console.log("000000", data)


    },
    goodsReceiving() {
      this.$router.push("/receiving");
    },
    closeDialog() {
      this.manualDialog = false;
      this.goodListTable = "";
      this.$refs.closeChild.closeKeyword();
    },
    // 清空登录信息
    ...mapMutations(["clearUserInfo"]),
    manualInput() {
      this.manualDialog = true;
      this.removeFocus();
    },
    updateWindowDimensions() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight + 20;
    },
    showPayResult(result) {
      this.checkPay = false;

      if (result) {
        this.payResult = true;
      } else {
        console.log(result);
        this.payFailed = true;
      }
      this.startCountdown();
    },
    startCountdown() {
      this.countdown = 3;
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.close();
        }
      }, 1000);
    },
    close() {
      if (this.payResult) {
        this.payResult = false;
      }
      if (this.payFailed) {
        this.payFailed = false;
      }
      clearInterval(this.countdownInterval);
    },
    openDialoged() {
      console.log("123456789")
      this.showPay = true;
    },
    noShowPay() {
      //需要销毁支付组件页面，保证下次再次打开时候数据是最新的
      (this.showPay = false), (this.checkPay = false), this.autoFunction();
    },
    gotoPay() {
      if (this.totalPrice == 0) {
        this.$message.warning("请先添加商品");
        return;
      }
      this.checkPay = true;
      this.removeFocus();
    },
    removeFocus() {
      if (this.$refs.inputRef) {
        // console.log("放弃焦点");
        this.$refs.inputRef.blur();
      }


    },


    autoFunction() {
      if (this.$refs.inputRef) {
        //console.log('自动聚焦')
        this.$refs.inputRef.focus();
      }
    },
    // 输入框失去焦点
    blur() {
      //判断不是打开拟态框的情况
      if (this.checkPay || this.manualDialog) {
      } else {
        this.autoFunction();
      }
    },

    logout(id) {
      //弹出确定退出确认框
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$router.replace({path: "/login"});
            this.$http
                .get(`/shopUser/setRecentShop?shopId=${id}&type=set`)
                .then((res) => {
                  if (res.success) {
                    this.$http.get(`/sys/logout`);
                    this.clearUserInfo();
                  }
                });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
            this.$refs.inputRef.focus();
          });
    },
    // 手动获取商品信息
    queryGoodsById(code, type) {
      // this.goodsCode = code;
      console.log("调用6666", code);
      if (code.length === 0 && type) {
        this.$message.warning("请输入商品码");
        return;
      }
      if (!type) {
        return (this.manualDialog = type);
      }
      if (type) {
        this.$http
            .get("/base/basMaterial/queryByMaterialId?id=" + code)
            .then((response) => {
              if (response.result == null) {
                this.$message.error(response.message);
                this.goodsCode = "";
              } else {
                for (let i = 0; i < response.result.length; i++) {
                  response.result[i].thumb = this.staticUrl(response.result[i].thumb)
                }
                this.goodListTable = response.result;
                console.log("打印", this.goodListTable);
              }
            })
            .catch((error) => {
              //请求异常
              this.$message.error(error);
            });
      }
      // this.manualDialog = type
    },
    // 打使用print.js打印小票
    async getBillNo() {
      try {
        const response = await this.$http.put(
            `/sys/fillRule/executeRuleByCode/stk_xsck_bill_no`,
            {}
        );
        if (response.success) {
          return response.result;
        } else {
          throw new Error(response.message || "Failed to get bill number");
        }
      } catch (error) {
        console.error("Error fetching bill number:", error);
        throw error;
      }
    },

    async submit(payInfo) {
      if (this.payState) {
        this.loading = true;
        this.payState = false


        let url = ""
        if (payInfo.payType == "swept") {
          url = "/mall/order/scanCodePlaceOrder?discount=" + payInfo.discount + "&type=swept&authCode=" + payInfo.authCode
        } else {
          url = "/mall/order/scanCodePlaceOrder?discount=" + payInfo.discount + "&type=cash"
        }


        try {
          const billNo = await this.getBillNo();
          const orderInfo = {
            billNo: billNo,
            billDate: this.getCurrentDateFormatted(),
            stockIoType: "201", //出入库类型
            customerId: "1760860143514984449", //客户id,使用默认客户
            invoiceType: "11", //发票类型,普通发票
            stkIoEntryList: await this.getGoodsInfos(),
            payType: payInfo.payType,
          };
          if (payInfo.zhaolingMoney) {
            orderInfo.changeMoney = payInfo.zhaolingMoney;
            orderInfo.payMoney = payInfo.payMoney;
          }

          const submitBill = await this.$http.post(
              url,
              orderInfo
          );
          console.log(submitBill);
          if (submitBill.success) {
            this.loading = false;
            // this.print(orderInfo, this.goods);
            this.showPay = false;
            this.showPayResult(true); //支付完成
            this.clearGoods();
          } else {
            this.loading = false;
            this.showPayResult(false); //支付失败
            this.$message.error(submitBill.message);
          }
        } catch (error) {
          this.loading = false;
          this.showPayResult(false);
          // this.$message.error("提交失败: " + error.message);
           console.error('Error submitting order:', error);
        } finally {
          this.payState = true
        }
      }

    },

    async getWarehouseAndInvoiceInfo(index) {//获取库存信息
      try {
        const response = await this.$http.get(
            `/stock/stkInventory/getInventory?batchNo=${this.batchNo}&materialId=${this.goods[index].id}&warehouseId=${this.shopInfo.id}`
        );
        if (response.success) {
          return response.result;
        } else {
          this.$message.error(response.message);
        }
      } catch (error) {
        this.$message.error("未知错误");
      }
    },

    async getGoodsInfos() {
      const goodsInfos = [];
      for (let index = 0; index < this.goods.length; index++) {
        const warehouseAndInvoiceInfo = await this.getWarehouseAndInvoiceInfo(
            index
        );
        const model =
            this.goods[index].model == null ? "" : this.goods[index].model;
        let name = this.goods[index].name + (this.goods[index].specificationName&&this.goods[index].specificationName!=null?this.goods[index].specificationName :"");
        goodsInfos.push({
          entryNo: index + 1,
          materialId: this.goods[index].id,
          materialModel: model,
          materialName: name,
          stockIoDirection: "2",
          warehouseId: this.shopInfo.id,
          batchNo: warehouseAndInvoiceInfo.batchNo,
          inventoryUnitId: warehouseAndInvoiceInfo.unitId,
          inventoryQty: this.goods[index].num,
          inventoryCost: this.goods[index].salePrice * this.goods[index].num,
          unitId: warehouseAndInvoiceInfo.unitId,
          qty: this.goods[index].num,
          cost: this.goods[index].salePrice,
          swellQty: 0,
          settleQty: this.goods[index].num,
          taxRate: "0",
          price: this.goods[index].salePrice,
          discountRate: 100,
          tax: 0,
          settleAmt: this.goods[index].salePrice * this.goods[index].num,
          remark: "",
          custom1: "",
          custom2: "",
          specification:this.goods[index].specification,
          isNational:this.goods[index].isNational
        });
      }
      return goodsInfos;
    },

    // 扫码输入
    showGoods(event) {
      console.log("走扫码");
      var num = String.fromCharCode(event.keyCode);
      this.goodsCode += num;
      if (this.goodsCode.length == 0) {
        return this.$message.error("商品码不能为空");
      }
      if (event.keyCode == 13) {
        this.$http
            .get("/base/basMaterial/queryByMaterialId?id=" + this.goodsCode)
            .then((response) => {
              if (response.result == null) {
                this.$message.error(response.message);
                this.goodsCode = "";
              } else if (response.result.length !== 1) {
                this.$message.error("有重复商品，联系管理员");
                this.goodsCode = "";
              } else {
                var tt = -1;
                for (let i = 0; i < this.goods.length; i++) {
                  if (this.goods[i].code === response.result[0].code&&!this.goods[i].specificationName) {
                    this.goods[i].num++;
                    this.goodsCode = "";
                    tt = 1;
                    break;
                  }
                }
                //tt作为一个信号量当tt不等于-1时表示商品已经存在，只需要添加数量
                if (tt === -1) {
                  response.result[0].num = 1;
                  var goods = response.result[0];
                  var tem = goods.thumb;
                  //  goods.thumb = process.env.VUE_APP_IMG_URL + tem;
                  this.goods.unshift(goods);
                  this.goodsCode = "";
                  this.inputMsg = "请使用扫码设备扫码";
                }
              }
            })
            .catch((error) => {
              //请求异常
              this.$message.error(error);
            });
      }
    },
    // 减少商品
    reduce(index) {
      if (this.goods[index].num <= 1) {
        this.goods.splice(index, 1);
      } else {
        this.goods[index].num--;
      }
    },
    // 增加商品
    addition(index) {
      this.goods[index].num++;
    },
    // 删除商品
    clearGoods() {
      this.goods = [];
      this.totalPrice = 0;
      this.totalCount = 0;
    },
    //向支付页面发送上商品详情
    sendGoodsTake() {
      var goodsData = {
        goods: this.goods,
        totalPrice: this.totalPrice,
        totalCount: this.totalCount,
      };
      return goodsData;
    },
    // 获取当前时间
    getCurrentDateFormatted() {
      // const now = new Date();
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, '0');
      // const day = String(now.getDate()).padStart(2, '0');
      // //return `${year}-${month}-${day} `;
      // //返回 yy-mm-dd hh:mm:ss

      // return `${year}-${month}-${day} `;

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //  把弹框上的商品添加到页面上
    handleAdd(index, row) {

      let found = false;
      for (let i = 0; i < this.goods.length; i++) {
        if (this.goods[i].code === row.code) {
          console.log(this.goods[i]);
          console.log(this.goods[i].num);
          this.goods[i].num++;
          found = true;
          break;
        }
      }
      if (!found) {
        row.num = 1;
        this.goods.push(row);
      }
      console.log(this.goods);
    },
  },
  mounted() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    setInterval(() => {
      this.now = new Date();
    }, 1000); // 更新时间每秒
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  },
  // 更新前需要进行的操作
  beforeUpdate() {

      console.log("#############",this.goods)
      var sum = 0;
      var sum1 = 0;
      for (let i = 0; i < this.goods.length; i++) {
        sum += this.goods[i].salePrice * this.goods[i].num;
        sum1 += this.goods[i].num;
      }
      this.totalPrice = sum.toFixed(2);
      this.totalCount = sum1;


  },
  updated() {
    console.log("uodated=================&&&&&&&&&&&&&&***********")
    if (this.checkPay || this.manualDialog) {
      this.removeFocus;
    } else {
      this.autoFunction();
    }
  },
};
</script>
<!--<script setup>-->
<!--import main from "../../components/main/index";-->
<!--</script>-->
<style scoped>
.list-wrapper{


}
.count-text{
  position: absolute;
  bottom: 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentinfo-box{
  position: relative;
  max-height: 82%;
  overflow: auto;
}
.btnstyle{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main_box {
  /* background: #0d015a; */
  width: 100vw;
  /* height: 90vh; */
  overflow: hidden;
}

.left {
  width: 30%;
  height: 100%;
}

.right {
  width: 70%;
  height: 100%;
  border-left: 1px solid #1d4350;
  margin-left: 10px;
}

.shouyin-header2 {
  background: #012155;
  color: #fff;
  /* line-height: 80px; */
  padding: 20px var(--padding) 5px;
  z-index: -1;
  display: flex;
  justify-content: space-between;
}

.other-list {
  display: flex;
  flex-direction: column;
}

.other-list-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.operator-name,
.time,
.status-icon {
  margin-right: 10px; /* 设置每个元素之间的间距 */
}

.status-icon {
  width: 20px;
  height: 20px;
}

.payment-success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
  z-index: 100000;
}

.payment-failed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
  z-index: 100000;
}

.popup {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: scaleIn 0.5s forwards;
}

.checkmark {
  font-size: 50px;
  color: #4caf50;
  animation: checkmark 0.5s ease-in-out;
}

.checkmark1 {
  font-size: 50px;
  color: #f90808;
  animation: checkmark1 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes checkmark {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
.item {
  margin-top: 10px;
  margin-right: 10px;
}
</style>
