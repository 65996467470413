<template>
  <div>
    <div class="mydiv">
      <el-input
        v-model="keyword"
        @input="searchEvent"
        placeholder="请输入商品名称或编号输入"
      ></el-input>
      <el-button @click="searchEvent()" type="primary" class="btn-end"
        >搜索</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      type: true,
      keyword: "",
      timer: null,
      len: false,
    };
  },
  mounted() {
  },
  methods: {
    closeKeyword(){
      this.keyword="";
    },
    // 监听输入框的变化，发起请求
    searchEvent() {
      this.clearTimer();
      if (this.keyword && this.keyword.length > 0) {
        this.len = true;
        console.log("123", this.keyword);
        this.timer = setTimeout(() => {
          console.log("daoy", this.keyword.trim());
          this.$emit("searchEvent", this.keyword.trim(), this.type);
          // this.keyword = "";
          //此处为接口函数
        }, 1000);
      } else {
        if (this.len) {
          console.log(this.keyword);
          this.$emit("searchEvent", this.keyword.trim(), this.type);
          // this.keyword = "";
          //此处为接口函数
        }
        if (this.keyword === "") {
          this.len = false;
          return;
        }
      }
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
};
</script>

<style scoped>
.mydiv{
  display: flex;
  margin-top: 5px;
}
.btn-end {
  width: 10%;
  margin-left: 2px;
  border-radius: 10%;
}
</style>
